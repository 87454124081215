import {REPORT_ACTIONS } from "../actions/actionTypes";

export function rp_metrics(state = [], action) {
    switch(action.type) {
        case REPORT_ACTIONS.GET_RP_METRICS_SUCCESS:
            return  { ...state, rp_metrics: action.rp_metrics }
        case REPORT_ACTIONS.GET_RP_METRICS_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}

export function avg_pg_category(state = [], action) {
    switch(action.type) {
        case REPORT_ACTIONS.AVG_PAGE_CATEGORY_SUCCESS:
            return  { ...state, avg_pg_category: action.avg_pg_category }
        case REPORT_ACTIONS.AVG_PAGE_CATEGORY_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}

export function avg_pg_loc(state = [], action) {
    switch(action.type) {
        case REPORT_ACTIONS.AVG_PAGE_LOC_SUCCESS:
            return  { ...state, avg_pg_loc: action.avg_pg_loc }
        case REPORT_ACTIONS.AVG_PAGE_LOC_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}

export function rp_graph(state = [], action) {
    switch(action.type) {
        case REPORT_ACTIONS.RP_GRAPH_SUCCESS:
            return  { ...state, rp_graph: action.rp_graph }
        case REPORT_ACTIONS.RP_GRAPH_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}