import { ArrowDropDown } from "@mui/icons-material";
import { Grid, IconButton, Menu, MenuItem } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import * as c from "../../../utils/constants/constants";

const ProfileMenu = (props) => {
    const [profileMenuEl, updatProfileMenuEl] = useState(null);

    const handleUserMenu = (e) => {
        updatProfileMenuEl(null);
    }
    return <Grid className="menu-holder">
        <div className="right-grid">

            <div className="icon-text">
                <div className="user-name-disp">
                    {props.profileData.name &&
                        <div>{props.profileData.name}</div>}
                    {localStorage.getItem(c.SETTINGS) &&
                        <div style={{ color: "white", fontSize: "0.8em" }}>
                            {JSON.parse(localStorage.getItem(c.SETTINGS)).Environment_Display_Name} environment
                        </div>}
                </div>
                <div className="vertical-line-nav"></div>
                {!props.isDuplicate ? <>
                    <div className="vertical-line-nav"></div>
                    <IconButton
                        aria-controls="simple-menu"
                        aria-haspopup="true"
                        onClick={e => updatProfileMenuEl(e.currentTarget)}
                        size="large">
                        <ArrowDropDown style={{ color: "white" }} />
                    </IconButton>
                </> : null}
                {Boolean(profileMenuEl) ? <Menu
                    id="simple-menu"
                    anchorEl={profileMenuEl}
                    keepMounted
                    onClose={e => updatProfileMenuEl(null)}
                    open={Boolean(profileMenuEl)}
                >
                    <Link to="/userprofile">
                        <MenuItem onClick={handleUserMenu}>Profile</MenuItem>
                    </Link>
                    <Link className="logout-item" to={c.SSO === 'true' ? `/${c.PAGE_URLS[c.SSO_REDIRECT]}` : '/'} >
                        <MenuItem onClick={handleUserMenu} >Logout</MenuItem>
                    </Link>

                </Menu> : null}
            </div>
        </div>
    </Grid>
}

export default ProfileMenu;