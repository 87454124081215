export const REPORT_ACTIONS = {
    GET_RP_METRICS_ERROR: 'GET_RP_METRICS_ERROR',
    GET_RP_METRICS_LOADING: 'GET_RP_METRICS_LOADING',
    GET_RP_METRICS_SUCCESS: 'GET_RP_METRICS_SUCCESS',

    RP_GRAPH_ERROR: 'RP_GRAPH_ERROR',
    RP_GRAPH_LOADING: 'RP_GRAPH_LOADING',
    RP_GRAPH_SUCCESS: 'RP_GRAPH_SUCCESS',

    AVG_PAGE_LOC_ERROR: 'AVG_PAGE_LOC_ERROR',
    AVG_PAGE_LOC_LOADING: 'AVG_PAGE_LOC_LOADING',
    AVG_PAGE_LOC_SUCCESS: 'AVG_PAGE_LOC_SUCCESS',

    AVG_PAGE_CATEGORY_ERROR: 'AVG_PAGE_CATEGORY_ERROR',
    AVG_PAGE_CATEGORY_LOADING: 'AVG_PAGE_CATEGORY_LOADING',
    AVG_PAGE_CATEGORY_SUCCESS: 'AVG_PAGE_CATEGORY_SUCCESS',
    


    
    
    
}