export const USER_MGMT_ACTIONS = {
    GET_USERS_ERROR: 'GET_CATEGORY_COUNT_ERROR',
    GET_USERS_LOADING: 'GET_CATEGORY_COUNT_LOADING',
    GET_USERS_SUCCESS: 'GET_CATEGORY_COUNT_SUCCESS',
    GET_ALL_USERS_ERROR: 'GET_ALL_USERS_ERROR',
    GET_ALL_USERS_LOADING: 'GET_ALL_USERS_LOADING',
    GET_ALL_USERS_SUCCESS: 'GET_ALL_USERS_SUCCESS',
    ADD_USER_ERROR: 'ADD_USER_ERROR',
    ADD_USER_LOADING: 'ADD_USER_LOADING',
    ADD_USER_SUCCESS: 'ADD_USER_SUCCESS',
    EDIT_USER_ERROR: 'ADD_USER_ERROR',
    EDIT_USER_LOADING: 'ADD_USER_LOADING',
    EDIT_USER_SUCCESS: 'ADD_USER_SUCCESS',
    GET_ALL_LOCATIONS_ERROR: 'GET_ALL_LOCATIONS_ERROR',
    GET_ALL_LOCATIONS_LOADING: 'GET_ALL_LOCATIONS_LOADING',
    GET_ALL_LOCATIONS_SUCCESS: 'GET_ALL_LOCATIONS_SUCCESS'
}