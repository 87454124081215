import { GET_DOCS_ACTIONS } from "../actions/actionTypes";

export function items(state = [], action) {
  switch (action.type) {
    case GET_DOCS_ACTIONS.GET_DOCS_STATUS_SUCCESS:
      return { ...state, items: action.items };
    case GET_DOCS_ACTIONS.GET_DOCS_STATUS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function recentdocs(state = [], action) {
  switch (action.type) {
    case GET_DOCS_ACTIONS.GET_RECENT_DOCS_SUCCESS:
      return { ...state, recentdocs: action.recentdocs };
    case GET_DOCS_ACTIONS.GET_RECENT_DOCS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function actionitems(state = [], action) {
  switch (action.type) {
    case GET_DOCS_ACTIONS.GET_ACTION_ITEMS_SUCCESS:
      return { ...state, actionitems: action.actionitems };
    case GET_DOCS_ACTIONS.GET_ACTION_ITEMS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function logs(state = [], action) {
  switch (action.type) {
    case GET_DOCS_ACTIONS.GET_LOGS_SUCCESS:
      return { ...state, logs: action.logs };
    case GET_DOCS_ACTIONS.GET_LOGS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function int_metrics(state = [], action) {
  switch (action.type) {
    case GET_DOCS_ACTIONS.GET_IMETRICS_SUCCESS:
      return { ...state, int_metrics: action.int_metrics };
    case GET_DOCS_ACTIONS.GET_IMETRICS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function perform_metrics(state = [], action) {
  switch (action.type) {
    case GET_DOCS_ACTIONS.GET_PMETRICS_SUCCESS:
      return { ...state, perform_metrics: action.perform_metrics };
    case GET_DOCS_ACTIONS.GET_PMETRICS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}
