import * as c from "../utils/constants/constants";
import api from "../utils/API/api";
import { TokenExpiry } from "./commonFunctions";

/*function to get new access token by passing refresh token to the API*/
const getRefreshToken = async() => {
    let resp = await api.refresh();
    localStorage.setItem(c.ACCESS_TOKEN, resp.data.data[0].auth_token);
    return localStorage.getItem(c.ACCESS_TOKEN);  
}

/*=========function to get tokens before every API call. It first checks for expiry. If expired, API call is made to get new token*/
export const getToken =  () => {
  if(!(localStorage.getItem(c.LOGOUT) === 'inactive' )){
    
  var header_data = null;
  /*if access token is present in local storage and not expired*/
  if (localStorage.getItem(c.ACCESS_TOKEN) && TokenExpiry(localStorage.getItem(c.ACCESS_TOKEN)) === true) {
    header_data = localStorage.getItem(c.ACCESS_TOKEN);
  }
  /* if access token is expired, check for refresh token and make API call*/ 
  else {
    if (localStorage.getItem(c.REFRESH_TOKEN)){
      if(TokenExpiry(localStorage.getItem(c.REFRESH_TOKEN)) === true) {
          header_data =   getRefreshToken();
      }
      /*if both access and refresh tokens are expired*/ 
      else {
        localStorage.setItem(c.LOGOUT,'expired');
        localStorage.removeItem(c.ACCESS_TOKEN);
        localStorage.removeItem(c.REFRESH_TOKEN);
        if(c.SSO === 'true') window.location.href = `/${c.PAGE_URLS[c.SSO_REDIRECT]}?s=expired` ;
        else window.location.href = `/${c.PAGE_URLS[c.PAGE_INFO]}?s=expired`;
    }}
  }
 
  return header_data;
}
};
