import { USER_PROFILE_ACTIONS } from "../actions/actionTypes";

export function userprofiledata(state = [], action) {
  switch (action.type) {
    case USER_PROFILE_ACTIONS.GET_USER_PROFILE_SUCCESS:
      return { ...state, userprofiledata: action.userprofiledata };
    case USER_PROFILE_ACTIONS.GET_USER_PROFILE_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}
