import {AUDIT_ACTIONS } from "../actions/actionTypes";

export function audit(state = [], action) {
    switch(action.type) {
        case AUDIT_ACTIONS.GET_AUDIT_SUCCESS:
            return  { ...state, audit: action.audit }
        case AUDIT_ACTIONS.GET_AUDIT_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}


export function audit_all(state = [], action) {
    switch(action.type) {
        case AUDIT_ACTIONS.GET_ALL_AUDIT_SUCCESS:
            return  { ...state, audit_all: action.audit_all }
        case AUDIT_ACTIONS.GET_ALL_AUDIT_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}

export function auditResult(state = [],action){
    switch(action.type) {
        case AUDIT_ACTIONS.AUDIT_RESULT_SUCCESS:
            return  { ...state, auditResult: action.auditResult }
        case AUDIT_ACTIONS.AUDIT_RESULT_ERROR:
            return {...state, hasError: action.hasError}
        default:
            return state;
    }
}
