import { GNRL_SETTINGS_ACTION } from "../actions/actionTypes";
import * as c from "../../../utils/constants/constants";

let defaultState = localStorage.getItem(c.SETTINGS) && JSON.parse(localStorage.getItem(c.SETTINGS)) || {};

export function settings(state = {settings: defaultState}, action) {
    switch(action.type) {
        case GNRL_SETTINGS_ACTION.GNRL_SETTINGS_SUCCESS: {
            localStorage.setItem(c.SETTINGS, JSON.stringify(action.settings));
            return { ...state, settings: action.settings}
        }
        default:
            return state;
    }
}


export function doc_id(state = [], action) {
    switch(action.type) {
        case GNRL_SETTINGS_ACTION.PASS_DOC_ID:
            return { ...state, doc_id: action.doc_id}
        default:
            return state;
    }
}

export function selectedMenu(state = [], action) {
    switch(action.type) {
        case GNRL_SETTINGS_ACTION.UPDATE_SELECTED_MENU:
            return action.selectedMenu
        default:
            return state;
    }
}
