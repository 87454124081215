import React from 'react';

class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = 
      { hasError: false ,
        error : '',
        error_info : ''
    };

    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    componentDidCatch(error, errorInfo) {
        this.setState({error : error, error_infor : errorInfo})
     
    }
  
    render() {
      if (this.state.hasError) {
        return (
        <div className = "error-boundary-msg">Something went wrong. </div>
        )
      }
  
      return this.props.children; 
    }
  }

export default ErrorBoundary;