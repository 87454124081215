import { USER_MGMT_ACTIONS } from "../actions/actionTypes";

export function usertabledata(state = [], action) {
  switch (action.type) {
    case USER_MGMT_ACTIONS.GET_USERS_SUCCESS:
      return {
        ...state,
        usertabledata: action.usertabledata,
        total_records: action.total_records,
      };
    case USER_MGMT_ACTIONS.GET_USERS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function allusers(state = [], action) {
  switch (action.type) {
    case USER_MGMT_ACTIONS.GET_ALL_USERS_SUCCESS:
      return { ...state, allusers: action.allusers };
    case USER_MGMT_ACTIONS.GET_ALL_USERS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function alllocations_user(state = [], action) {
  switch (action.type) {
    case USER_MGMT_ACTIONS.GET_ALL_LOCATIONS_SUCCESS:
      return { ...state, alllocations_user: action.alllocations_user };
    case USER_MGMT_ACTIONS.GET_ALL_LOCATIONS_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}

export function adduserresponse(state = [], action) {
  switch (action.type) {
    case USER_MGMT_ACTIONS.ADD_USER_SUCCESS:
      return { ...state, adduserresponse: action.adduserresponse };
    case USER_MGMT_ACTIONS.ADD_USER_ERROR:
      return { ...state, hasError: action.hasError };
    default:
      return state;
  }
}
