export const GET_DOCS_ACTIONS = {
    GET_DOCS_STATUS_LOADING: 'GET_DOCS_STATUS_LOADING',
    GET_DOCS_STATUS_ERROR: 'GET_DOCS_STATUS_ERROR',
    GET_DOCS_STATUS_SUCCESS: 'GET_DOCS_STATUS_SUCCESS',

    GET_RECENT_DOCS_LOADING: 'GET_RECENT_DOCS_LOADING',
    GET_RECENT_DOCS_ERROR: 'GET_RECENT_DOCS_ERROR',
    GET_RECENT_DOCS_SUCCESS: 'GET_RECENT_DOCS_SUCCESS',

    GET_ACTION_ITEMS_LOADING: 'GET_ACTION_ITEMS_LOADING',
    GET_ACTION_ITEMS_ERROR: 'GET_ACTION_ITEMS_ERROR',
    GET_ACTION_ITEMS_SUCCESS: 'GET_ACTION_ITEMS_SUCCESS',

    GET_LOGS_LOADING: 'GET_LOGS_LOADING',
    GET_LOGS_ERROR: 'GET_LOGS_ERROR',
    GET_LOGS_SUCCESS: 'GET_LOGS_SUCCESS' ,

    GET_IMETRICS_LOADING: 'GET_IMETRICS_LOADING',
    GET_IMETRICS_ERROR: 'GET_IMETRICS_ERROR',
    GET_IMETRICS_SUCCESS: 'GET_IMETRICS_SUCCESS', 

    GET_PMETRICS_LOADING: 'GET_PMETRICS_LOADING',
    GET_PMETRICS_ERROR: 'GET_PMETRICS_ERROR',
    GET_PMETRICS_SUCCESS: 'GET_PMETRICS_SUCCESS' 
}