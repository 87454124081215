import React from "react";
import { useHistory } from "react-router-dom";
import * as c from "../../utils/constants/constants";
import "./NotFound.css";

const NotFound = (props) => {
    const history = useHistory();
    const goToHomePage = () => {
        if(localStorage.getItem(c.ACCESS_TOKEN)) history.push(`/${c.PAGE_URLS[c.PAGE_DASHBOARD]}`);
        else {
            if(c.SSO == "true") history.push(`/${c.PAGE_URLS[c.SSO_REDIRECT]}`);
            else history.push(`/`);
        }
    }
    return <div style={{ display: "flex", justifyContent: "center", flexDirection: "column", marginTop: "15px" }}>
        <h1 className="not-found-text">404 - Page Not Found</h1>
        <div className="info">
            {" "}
            Please{" "}
            <span className="info-link" onClick={goToHomePage}>
                click here
            </span>{" "}
            to continue {localStorage.getItem(c.ACCESS_TOKEN) ? " using " : " login "} the application.
        </div>
    </div>
}

export default NotFound;