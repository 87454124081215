export const GET_MANUAL_REVIEW_DATA_ACTIONS = {
    GET_MANUAL_REVIEW_DATA_LOADING: 'GET_MANUAL_REVIEW_DATA_LOADING',
    GET_MANUAL_REVIEW_DATA_ERROR: 'GET_MANUAL_REVIEW_DATA_ERROR',
    GET_MANUAL_REVIEW_DATA_SUCCESS: 'GET_MANUAL_REVIEW_DATA_SUCCESS',

    GET_IMAGE_URL_LOADING: 'GET_IMAGE_URL_LOADING',
    GET_IMAGE_URL_ERROR: 'GET_IMAGE_URL_ERROR',
    GET_IMAGE_URL_SUCCESS: 'GET_IMAGE_URL_SUCCESS',

    GET_CATEGORIES_LOADING: 'GET_CATEGORIES_LOADING',
    GET_CATEGORIES_ERROR: 'GET_CATEGORIES_ERROR',
    GET_CATEGORIES_SUCCESS: 'GET_CATEGORIES_SUCCESS',

    GET_CATEGORY_MAPPING_LOADING: 'GET_CATEGORY_MAPPING_LOADING',
    GET_CATEGORY_MAPPING_ERROR: 'GET_CATEGORY_MAPPING_ERROR',
    GET_CATEGORY_MAPPING_SUCCESS: 'GET_CATEGORY_MAPPING_SUCCESS'
}